/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.mdc-floating-label--required:not(
    .mdc-floating-label--hide-required-marker
  )::after {
  color: red;
}
